import Generator from "../Components/Generator.js";
import { signal, effect } from "@preact/signals-react";

const status = signal();
effect(() => {
  fetch("https://eliisa.19thfleet.org/elyfia")
    .then((response) => response.text())
    .then((text) => (status.value = text));
});
function Home() {
  return (
    <div className="mx-auto flex flex-wrap">
      <Generator />
      {status.value}
    </div>
  );
}

export default Home;
