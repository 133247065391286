import "../App.css";
import React from "react";
import NavList from "./NavList";
import Clock from "./Clock";
import { NavLink } from "react-router-dom";

const destinations = [
  { title: "Styleguide", url: "styleguide" },
  { title: "LIA Grid", url: "lia-grid" },
  { title: "X4 Dashboard", url: "x4-dashboard" },
];

function Sidebar() {
  return (
    <div className="relative max-h-96 w-32 flex-col bg-alliance-5 pt-2 before:absolute before:left-20 before:top-0 before:w-0 before:border-b-14 before:border-l-14 before:border-t-14 before:border-solid before:border-y-white before:border-l-alliance-5">
      <div className="h-1/2">
        <h1 className="pb-4 text-center text-3xl font-bold text-megan-5">
          <NavLink to="/">Eliisa</NavLink>
        </h1>
        <NavList
          destinations={destinations}
          navClasses="pl-2"
          ulClasses="text-right"
          liClasses="text-megan-5 hover:text-lia-3 [&_.active]:text-lia-5 hover:bg-gradient-to-l [&_.active]:bg-gradient-to-l from-alliance-3 [&_.active]:border-r-2 [&_.active]:border-lia-5"
          linkClasses="pr-2 inline-block w-full"
        />
        <Clock />
      </div>
    </div>
  );
}
export default Sidebar;
